import { EmployeeEarning } from "../../api";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { useLocales } from "../../hooks";
import { LevelLogo } from "../LevelSection/LevelLogo.tsx";
import { EmployeeLevelData } from "../LevelSection/consts.tsx";
import { GamificationAchievementContent } from "./GamificationAchievementContent.tsx";
import GoldStartIcon from "assets/goldStar.svg?react";
interface NewLevelProps {
  badge?: EmployeeEarning["badge"];
}

export const BadgeWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  alignItems: "center",
});

export function NewLevel({ badge }: NewLevelProps) {
  const { translate } = useLocales();
  const levelKind = badge?.level;
  const score = badge?.badge_score ?? 0;

  return levelKind ? (
    <BadgeWrapper>
      <Typography color="text.primary" fontSize="24px" fontWeight="700">
        {translate("assessment.newLevel.title")}
      </Typography>
      <Typography color="text.primary" fontSize="18px" fontWeight="400">
        {translate("assessment.newLevel.subtitle")}
      </Typography>
      <LevelLogo levelKind={levelKind} sx={{ width: "170px", height: "170px" }} />

      <Typography
        color="text.primary"
        fontSize="20px"
        fontWeight="500"
        lineHeight="normal"
        textAlign="center"
      >
        {translate("assessment.newLevel.description", {
          name: EmployeeLevelData[levelKind]?.title,
        })}
      </Typography>
      <Box width="166px">
        <GamificationAchievementContent
          title={translate("assessment.newLevel.yourPoints")}
          content={String(score)}
          icon={<GoldStartIcon />}
        />
      </Box>
    </BadgeWrapper>
  ) : null;
}
