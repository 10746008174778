import { Box, styled } from "@mui/material";

export const Wrapper = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  borderRadius: 8,
  overflow: "hidden",
  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  backgroundColor: "white",
});

export const Header = styled(Box)({
  display: "flex",
  padding: "8px 20px",
  justifyContent: "center",
  alignItems: "center",
  gap: 8,
  background: "#000000",
  backdropFilter: "blur(60px)",
});

export const Content = styled(Box)({
  display: "flex",
  flexDirection: "column",
  padding: "12px 20px",
  alignItems: "center",
  flex: 1,
});
