import { useLoginContext } from "../api/loginContext.ts";
import { AxiosResponse } from "axios";
import { apiHandlers, AssessmentResponseData } from "../api";
import { useHandleRedirectToBadgePage } from "./useHandleRedirectToBadgePage.ts";
import { isArray } from "lodash";

const MAX_RETRY_TIME_SEC = 50;
const BASE_DELAY_IN_MS = 100;
const DELAY_GROWTH = 1.2;
const MAX_DELAY_IN_MS = 2000;

export const useOutlookAssessmentShortPolling = () => {
  const { accessToken } = useLoginContext();
  let currentDelay = BASE_DELAY_IN_MS;
  let totalWaitTime = 0;

  const handleRedirectToBadgePage = useHandleRedirectToBadgePage();
  let alreadySeen = Boolean(sessionStorage.getItem("assessmentResult"));

  return async (result: AxiosResponse<AssessmentResponseData>) => {
    while (result.data.status !== "DONE" && result.data.status !== "ERROR") {
      await new Promise((resolve) => setTimeout(resolve, currentDelay));
      totalWaitTime += currentDelay / 1000;
      if (totalWaitTime > MAX_RETRY_TIME_SEC) {
        throw new Error("Assessment took too long to complete");
      }
      currentDelay = Math.min(MAX_DELAY_IN_MS, currentDelay * DELAY_GROWTH);
      result = await apiHandlers.assessment.check(
        {
          id: result.data.id,
        },
        { headers: { Authorization: `${accessToken}` } },
      );

      if (
        isArray(result?.data?.assessment_result?.earnings) &&
        result.data.assessment_result.earnings.length > 0 &&
        !alreadySeen
      ) {
        alreadySeen = true;
        handleRedirectToBadgePage(result.data.assessment_result);
      }
    }

    if (result.data.status === "ERROR") {
      sessionStorage.removeItem("assessmentResult");
    }
    return result;
  };
};
