import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { defaultNS, resources } from "./constants.ts";
import { getCurrentCachedLocale } from "./getCurrentCachedLocale.ts";

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: getCurrentCachedLocale(),
    resources,
    fallbackLng: "ja",
    debug: false,
    ns: [defaultNS],
    defaultNS,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
