import { ReactNode } from "react";
import { Content, Header, Wrapper } from "./styled.ts";
import { Box, Typography } from "@mui/material";

type GamificationAchievementContentProps = {
  title: string;
  content: string;
  icon: ReactNode;
};

export function GamificationAchievementContent({
  title,
  content,
  icon,
}: GamificationAchievementContentProps) {
  return (
    <Wrapper>
      <Header>
        <Box sx={{ display: "flex", alignSelf: "center" }}>{icon}</Box>
        <Typography variant="body1" fontWeight={500} color="white">
          {title}
        </Typography>
      </Header>
      <Content>
        <Typography fontSize="16px" fontWeight="700" color="#0057FF">
          {content}
        </Typography>
      </Content>
    </Wrapper>
  );
}
