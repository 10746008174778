interface StorageInterface {
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
}

function isLocalStorageAvailable(): boolean {
  const testKey = "test";
  try {
    localStorage.setItem(testKey, "1");
    localStorage.removeItem(testKey);
    return true;
  } catch (error) {
    return false;
  }
}

const storageAvailable = isLocalStorageAvailable();

function getItem(key: string): string | null {
  if (storageAvailable) {
    return localStorage.getItem(key);
  } else {
    console.warn("localStorage is not available. Using fallback storage.");
    return fallbackStorage.getItem(key);
  }
}

function setItem(key: string, value: string): void {
  if (storageAvailable) {
    localStorage.setItem(key, value);
  } else {
    console.warn("localStorage is not available. Using fallback storage.");
    fallbackStorage.setItem(key, value);
  }
}

function removeItem(key: string): void {
  if (storageAvailable) {
    localStorage.removeItem(key);
  } else {
    console.warn("localStorage is not available. Using fallback storage.");
    fallbackStorage.removeItem(key);
  }
}

const fallbackStorage: StorageInterface = (() => {
  const inMemoryStore: { [key: string]: string } = {};
  return {
    getItem(key: string): string | null {
      return inMemoryStore[key] || null;
    },
    setItem(key: string, value: string): void {
      inMemoryStore[key] = value;
    },
    removeItem(key: string): void {
      delete inMemoryStore[key];
    },
  };
})();

export { getItem, setItem, removeItem, fallbackStorage };
