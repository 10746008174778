import { Progress } from "./Progress";
import { Grid, SxProps } from "@mui/material";
import { useLocales } from "../hooks";
import { ReactNode } from "react";

export interface GeneralLayoutProps {
  isOfficeInitialized: boolean;
  children?: ReactNode;
  sx?: SxProps;
}

export function GeneralLayout({
  isOfficeInitialized,
  children,
  sx,
}: GeneralLayoutProps) {
  const { translate } = useLocales();

  if (!isOfficeInitialized) {
    return <Progress message={translate("common.globalLoading")} />;
  }

  return (
    <Grid
      container
      flexDirection="column"
      alignItems="center"
      height="100%"
      px="16px"
      sx={{ ...sx, overflowY: "auto", backgroundColor: "background.default" }}
    >
      {children}
    </Grid>
  );
}
