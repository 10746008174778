import { HASH_MARK_COUNT, RISK_LEVELS } from "./constants";

export const calculateOpacity = (riskIndex: number, i: number) => {
  const currentIndex =
    (riskIndex * (HASH_MARK_COUNT - 1)) / (RISK_LEVELS.length - 1);
  const remainingHashMarks = HASH_MARK_COUNT - currentIndex;

  if (i <= currentIndex) {
    return 1;
  } else {
    const relativeIndex = i - currentIndex;
    const opacityStep = 1 / remainingHashMarks;
    return 1 - relativeIndex * opacityStep;
  }
};
