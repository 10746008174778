import { AssessmentResponseData, AssessmentResult } from "../api";

export function calculateAssessmentStatus(
  result?: AssessmentResult,
  status?: AssessmentResponseData["status"],
) {
  if (result?.assessment?.error) {
    return "error";
  }

  if (result?.assessment?.level === "BYPASS") {
    return "bypass";
  }

  if (status === "DONE") {
    return "success";
  }

  return "loading";
}
