import { EmployeeEarning } from "../../api";
import { RewardEvent } from "./types.ts";

export const parseEarningsToRewardEvents = (earnings: EmployeeEarning[]): RewardEvent[] => {
  return earnings.flatMap((earning) => {
    const result: RewardEvent[] = [];

    if (earning.score) {
      result.push({
        type: "POINTS",
        ...earning,
      });
    }

    if (earning.badge) {
      if (earning.badge.kind === "NEW_LEVEL") {
        result.push({
          type: "LEVEL_UP",
          ...earning,
        });
      } else {
        result.push({
          type: "BADGE",
          ...earning,
        });
      }
    }

    return result;
  });
};
