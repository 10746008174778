import { rest } from "msw";

import { apiPaths, AssessmentResponseData } from "../../api";

const data: AssessmentResponseData = {
  assessment_result: {
    assessment: {
      error: null,
      level: "MODERATE_HIGH",
      details: [
        {
          title: "Beware of Urgent Action Requests",
          description:
            "A prevalent method used by cybercriminals in phishing emails involves the application of scare tactics, pressing you to execute urgent actions related to your account. These messages may insinuate that your account has been compromised, and immediate action is required to prevent disastrous consequences. It's important to understand that legitimate organizations, such as Bank of America, typically would not solicit customers to perform urgent actions via email. Always approach such emails with caution. Don't click on any links or download any attachments within the email, and instead, reach out directly to your bank through their official contact channels to verify the communication's legitimacy. Remember, when in doubt, it's always better to be safe than sorry",
        },
        {
          title: "Pay attention to links",
          description:
            "Malicious links are another common tool in the hacker's arsenal, often embedded in seemingly innocuous emails or messages. Clicking on these harmful links can lead to the installation of malware or facilitate other types of cyberattacks. Therefore, it's crucial to educate all members of an organization about the dangers of clicking on unverified links, even if they appear to be from known contacts. Use a trusted security software to scan for and block malicious links and always verify the legitimacy of a link before clicking on it.",
        },
      ],
    },
    forward: {
      error: null,
    },
    message_id: "someMessageId",
  },
  status: "DONE",
  id: "1",
};

export const getAttackStatistics = rest.post(`/api${apiPaths.assessment.check}`, (_, res, ctx) => {
  return res(ctx.status(200), ctx.json(data));
});
