import { useLocales } from "../../hooks";
import { useAssessmentQuery } from "../../hooks/useAssessmentQuery.ts";
import { calculateAssessmentStatus } from "../../utils/calculateAssessmentStatus.ts";

export function useAssessmentData() {
  const { translate } = useLocales();

  const { data, status } = useAssessmentQuery();
  const detailsStatus = calculateAssessmentStatus(data);

  const assessmentStatus = status === "success" ? detailsStatus : status;
  const forwardStatus: "success" | "error" = "success";

  return {
    translate,
    assessmentStatus,
    forwardStatus,
    data,
  };
}
