import { useQuery } from "react-query";
import { apiHandlers, GamificationSummary } from "../api";
import { AxiosError } from "axios";
import { useLoginContext } from "../api/loginContext";
import { UseQueryOptions } from "react-query/types/react/types";

export function useBadgeData(
  options?: Omit<
    UseQueryOptions<GamificationSummary, AxiosError, GamificationSummary>,
    "queryKey" | "queryFn"
  >,
) {
  const { accessToken, markIdentityAPIFailed } = useLoginContext();

  return useQuery<GamificationSummary, AxiosError>(
    ["badgeData", accessToken],
    () =>
      apiHandlers.info
        .getBadgeData({
          headers: {
            Authorization: `${accessToken}`,
          },
        })
        .then((response) => response.data)
        .catch((error) => {
          if (error.response?.status === 400) {
            markIdentityAPIFailed?.();
          }
          return Promise.reject(error);
        }),
    options,
  );
}
