import enLocales from "./en.json";
import jpLocales from "./jp.json";
// import heLocales from "./he.json";
// import zhLocales from "./zh.json";

export const defaultNS = "translations";
export const resources = {
  en: { translations: enLocales },
  ja: { translations: jpLocales },
  // TODO: add the locales when we decide to enable them
  // he: { translations: heLocales },
  // zh: { translations: zhLocales },
} as const;

export const defaultLocale = "ja";
