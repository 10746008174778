import { Box, Typography } from "@mui/material";
import { useLocales } from "../../hooks";
import { LevelProgressBar } from "../LevelSection/LevelProgressBar.tsx";

import { LevelLogo } from "../LevelSection/LevelLogo.tsx";
import { EmployeeLevelData } from "../LevelSection/consts.tsx";
import { LevelProgressBarWrapper, StyledTypography } from "./styled.ts";
import { EmployeeLevel } from "../../api";

type LevelInformationProps = {
  score: number;
  currentLevel?: EmployeeLevel;
  nextLevel?: EmployeeLevel;
};

export function LevelInformation({ score, currentLevel, nextLevel }: LevelInformationProps) {
  const { translate } = useLocales();
  const levelKind = currentLevel?.kind ?? "NOVICE";
  const isMaxScoreView = score >= EmployeeLevelData.GUARDIAN.minScore;
  const progress = isMaxScoreView ? 100 : Math.floor((score * 100) / (nextLevel?.points ?? 1));
  const nextLevelPoints = Number(nextLevel?.points ?? 0) - 1;
  return (
    <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
      <LevelLogo levelKind={levelKind} sx={{ width: "120px", height: "120px" }} />
      <Typography
        textAlign="center"
        fontSize="16px"
        fontWeight="600"
        color="text.primary"
        mb="20px"
      >
        {EmployeeLevelData[levelKind]?.title}
      </Typography>
      <LevelProgressBarWrapper>
        <StyledTypography>{score}</StyledTypography>
        <LevelProgressBar
          progress={progress}
          backgroundSx={{
            height: "inherit",
          }}
        />
      </LevelProgressBarWrapper>
      {nextLevelPoints >= 0 ? (
        <Typography textAlign="center" fontSize="14px" fontWeight="400" color="text.primary">
          {translate("assessment.badges.nextLevel", {
            points: nextLevelPoints,
          })}
        </Typography>
      ) : null}
    </Box>
  );
}
