import { useMutation } from "react-query";
import { useLoginContext } from "../../api/loginContext.ts";
import { apiHandlers, PostApiAssessmentReportByMessageIdFeedbackApiArg } from "../../api";
import { routes } from "../../settings/routes.ts";
import { useHistory } from "react-router-dom";

export function useReportFeedback() {
  const { accessToken } = useLoginContext();
  const history = useHistory();
  return useMutation(
    (data: PostApiAssessmentReportByMessageIdFeedbackApiArg) => {
      return apiHandlers.assessment.reportFeedback(data, {
        headers: { Authorization: `${accessToken}` },
      });
    },
    {
      onSuccess: () => {
        history.push(routes.assessment.thankYou.index);
      },
    },
  );
}
