import { getBadgeData } from "../../components/Badges/consts.tsx";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { useLocales } from "../../hooks";
import { format } from "date-fns";
import { EmployeeEarningBadge } from "../../api";

export const BadgeWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  alignItems: "center",
});
export const IconWrapper = styled(Box)({
  width: 100,
  height: 100,
  flexShrink: 0,
  marginBottom: 20,
});

interface EarnedBadgeProps {
  badgeKind: EmployeeEarningBadge["kind"];
  badgeDate?: EmployeeEarningBadge["badge_date"];
}

export function EarnedBadge({ badgeKind, badgeDate }: EarnedBadgeProps) {
  const { translate } = useLocales();
  const badgeData = getBadgeData(badgeKind);

  return badgeData ? (
    <BadgeWrapper>
      <IconWrapper>{badgeData?.icon}</IconWrapper>
      <Typography color="text.primary" fontSize="20px" fontWeight="700">
        {translate(badgeData.title)}
      </Typography>
      <Typography
        color="text.secondary"
        fontSize="16px"
        fontWeight="400"
        lineHeight="normal"
        textAlign="center"
      >
        {translate(badgeData?.modal.description as unknown as TemplateStringsArray, {
          date: format(new Date((badgeDate ?? 0) * 1000), "MMMM yyyy"),
        })}
      </Typography>
    </BadgeWrapper>
  ) : null;
}
