import { Button, styled, Typography, Box } from "@mui/material";
import { MainLogo } from "../components/MainLogo.tsx";
import { useLocales } from "../hooks";
import errorImage from "../assets/access-denied.png";
import imgUrl from "../assets/main-logo.png";
import { useHistory } from "react-router-dom";
import { routes } from "../settings/routes.ts";
import { useUserInfoQuery } from "../hooks/useUserInfoQuery.ts";
import { ErrorView } from "../components/ErrorView.tsx";
import { Trans } from "react-i18next";
import { InformativeView } from "../components/InformativeView";
import { Progress } from "../components/Progress.tsx";
import ReportIcon from "assets/report.svg?react";
import SearchGuardIcon from "assets/searchGuard.svg?react";
import { isFeatureEnabled } from "../featureFlags";
const DescriptionText = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  color: #5f6368;
  text-align: center;
`;

export function MainScreen() {
  const { translate } = useLocales();
  const history = useHistory();
  const { data, error, isLoading, isFetched } = useUserInfoQuery();

  if (isLoading || !isFetched) {
    return <Progress message="" />;
  }

  if (data?.soc_email) {
    const config = data?.config;

    return (
      <>
        <Box mb="24px">
          <MainLogo src={imgUrl} alt="AironWorks logo" />
        </Box>

        {config?.assessment_button ? (
          <>
            <Button
              fullWidth
              variant="contained"
              sx={{ mb: "16px", fontSize: 12, padding: "8px 26px 9px" }}
              onClick={() => {
                history.push(routes.assessment.index);
              }}
              startIcon={isFeatureEnabled("PS-AIGeneratedAttacks") ? <SearchGuardIcon /> : null}
            >
              {config.assessment_button_text || translate("mainScreen.assessmentAction")}
            </Button>
            {config?.assessment_button_description ? (
              <DescriptionText sx={{ mb: "16px" }}>
                {config?.assessment_button_description}
              </DescriptionText>
            ) : null}
          </>
        ) : null}

        {config?.incident_button ? (
          <>
            <Button
              fullWidth
              variant="outlined"
              sx={{ mb: "16px", fontSize: 12, padding: "8px 26px 9px" }}
              onClick={() => {
                history.push(routes.incident.index);
              }}
              startIcon={isFeatureEnabled("PS-AIGeneratedAttacks") ? <ReportIcon /> : null}
            >
              {config.incident_button_text || translate("mainScreen.incidentAction")}
            </Button>
            {config?.incident_button_description ? (
              <DescriptionText sx={{ mb: "16px" }}>
                {config?.incident_button_description}
              </DescriptionText>
            ) : null}
          </>
        ) : null}

        {config?.subtext ? (
          <DescriptionText sx={{ mb: "16px" }}>{config?.subtext}</DescriptionText>
        ) : null}
      </>
    );
  }

  switch (error?.response?.status) {
    case 401:
      return (
        <InformativeView
          title={translate("common.errors.authenticationFailed.title")}
          imgSrc={errorImage}
          description={
            <Trans
              i18nKey="common.errors.authenticationFailed.description"
              components={{
                1: <p style={{ textAlign: "start" }} />,
              }}
            />
          }
        />
      );
    case 403:
      return (
        <InformativeView
          title={translate("common.errors.accessDenied.title")}
          imgSrc={errorImage}
          description={
            <Trans
              i18nKey="common.errors.accessDenied.description"
              components={{
                1: <p style={{ textAlign: "start" }} />,
                2: <ul />,
                3: <li style={{ textAlign: "start" }} />,
                4: <strong />,
                5: <a href="mailto:info@aironworks.com" />,
              }}
            />
          }
        />
      );
    default:
      return <ErrorView description={translate("common.errors.unavailableAssessment")} />;
  }
}
