import { createTheme, responsiveFontSizes } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    alert: {
      success: string;
      error: string;
      info: string;
    };
  }
  interface PaletteOptions {
    alert?: {
      success?: string;
      error?: string;
      info?: string;
    };
  }
}

const primaryColor = "#0057FF";

const baseTheme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: primaryColor,
    },
    text: {
      primary: "#111940",
      secondary: "rgba(17, 25, 64, 0.8)",
    },
    background: {
      default: "#f7f7fb",
      paper: "#f7f7fb",
    },
    alert: {
      success: "#D6F2DB",
      error: "#FFE3ED",
      info: "#DAEDFF",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: "8px 32px 9px",
          textTransform: "none",
          fontWeight: 600,
          fontSize: "14px",
        },
        containedPrimary: ({ theme }) => ({
          color: theme.palette.common.white,
        }),
        outlinedSecondary: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontWeight: 600,
          fontSize: "20px",
          lineHeight: "24px",
        },
        h2: {
          fontWeight: 600,
          fontSize: "14px",
          lineHeight: "17px",
        },
        body1: {
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "22px",
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: primaryColor,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          fontSize: "12px",
          padding: "8px",
        },
        standardSuccess: ({ theme }) => ({
          backgroundColor: theme.palette.alert.success,
          color: theme.palette.common.black,
        }),
        standardError: ({ theme }) => ({
          backgroundColor: theme.palette.alert.error,
          color: theme.palette.common.black,
        }),
        standardInfo: ({ theme }) => ({
          backgroundColor: theme.palette.alert.info,
          color: theme.palette.common.black,
        }),
      },
    },
  },
});

export const theme = responsiveFontSizes(baseTheme);
