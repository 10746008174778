import { useMutation } from "react-query";
import { apiHandlers, AssessmentRequestData, getMessageId } from "../api";
import { getAdaptedCacheLocale } from "../locales";
import { useLoginContext } from "../api/loginContext";
import { useOutlookAssessmentShortPolling } from "./useOutlookAssessmentShortPolling.ts";

export function useReportMutation() {
  const { accessToken } = useLoginContext();

  const outlookAssessmentShortPolling = useOutlookAssessmentShortPolling();

  return useMutation(
    async (data: Pick<AssessmentRequestData, "user_message">) => {
      sessionStorage.removeItem("assessmentResult");
      const { messageId, userId } = await getMessageId();
      console.log("mail id for report is", messageId);

      let result = apiHandlers.assessment.check(
        {
          ...data,
          message_id: messageId,
          source_mailbox: userId,
          language: getAdaptedCacheLocale(),
          token: accessToken ?? undefined,
        },
        { headers: { Authorization: `${accessToken}` } },
      );

      sessionStorage.setItem("isEmailAlreadyReported", JSON.stringify(true));

      result = outlookAssessmentShortPolling(await result);

      return result;
    },
    {
      onError: () => {
        sessionStorage.removeItem("isEmailAlreadyReported");
      },
    },
  );
}
