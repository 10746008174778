import { Typography } from "@mui/material";
import { useLocales } from "../../hooks";
import { useState } from "react";
import AccurateIcon from "../../assets/feedback/accurate.svg?react";
import NeutralIcon from "../../assets/feedback/neutral.svg?react";
import PoorIcon from "../../assets/feedback/poor.svg?react";
import { RadioButtonsGroup, RadioInput, RadioLabel } from "./styled.ts";
import { PostApiAssessmentReportByMessageIdFeedbackApiArg } from "../../api";

export function FeedbackRadioGroup() {
  const { translate } = useLocales();
  const [selected, setSelected] =
    useState<PostApiAssessmentReportByMessageIdFeedbackApiArg["data"]["level"]>("ACCURATE");

  const labels = [
    {
      label: translate("assessment.provideFeedback.radio.accurate"),
      value: "ACCURATE",
      icon: <AccurateIcon />,
    },
    {
      label: translate("assessment.provideFeedback.radio.neutral"),
      value: "NEUTRAL",
      icon: <NeutralIcon />,
    },
    {
      label: translate("assessment.provideFeedback.radio.poor"),
      value: "POOR",
      icon: <PoorIcon />,
    },
  ] as const;

  return (
    <RadioButtonsGroup>
      {labels.map(({ value, label, icon }) => (
        <RadioLabel key={value} selected={selected === value}>
          <RadioInput
            type="radio"
            name="radioFeedback"
            value={value}
            checked={selected === value}
            onChange={() => setSelected(value)}
          />
          {icon}
          <Typography fontSize="12px" fontWeight="400" color="text.secondary">
            {label}
          </Typography>
        </RadioLabel>
      ))}
    </RadioButtonsGroup>
  );
}
