import { useHistory } from "react-router-dom";
import { AssessmentResult } from "../api";
import { routes } from "../settings/routes.ts";
import { isFeatureEnabled } from "../featureFlags";
import { useUserInfoQuery } from "./useUserInfoQuery.ts";

export const useHandleRedirectToBadgePage = () => {
  const { data: infoData } = useUserInfoQuery();
  const history = useHistory();

  const isEnabledGamification = Boolean(infoData?.game_notification);

  return (assessmentResult: AssessmentResult) => {
    const earning = assessmentResult?.earnings ?? [];
    if (
      earning.length > 0 &&
      !sessionStorage.getItem("assessmentResult") &&
      isFeatureEnabled("PS-Gamification2-Rewards") &&
      isEnabledGamification
    ) {
      sessionStorage.setItem("assessmentResult", JSON.stringify(assessmentResult));

      history.push(routes.result.badge.index);
    }
  };
};
