import { Box } from "@mui/material";
import { EarningsContent } from "./EarningsContent.tsx";

import { AssessmentResult } from "../../api";
import { useMultiRewardsFlow } from "../../hooks/employeeAdvancement/useMultiRewardsFlow.ts";
import { BadgeContentActions } from "./BadgeContentActions.tsx";

function BadgeResultScreen() {
  const assessmentResult: AssessmentResult = JSON.parse(
    sessionStorage.getItem("assessmentResult") || "{}",
  );

  const earnings = assessmentResult.earnings ?? [];
  const { currentEvent, hasNext, showNextEvent } = useMultiRewardsFlow(earnings);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3, width: "100%" }}>
      <EarningsContent currentEvent={currentEvent} />
      <BadgeContentActions hasNext={hasNext} showNextEvent={showNextEvent} />
    </Box>
  );
}

export default BadgeResultScreen;
