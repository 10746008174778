import { InformativeView } from "./InformativeView";
import errorImage from "../assets/error-magnifying-glass.png";
import { ForwardingStatusProps } from "./ForwardingStatus.tsx";
import { useLocales } from "../hooks";
import { ReactNode } from "react";

type ErrorViewProps =
  | {
      description: ReactNode;
      forwardingStatus?: ForwardingStatusProps["status"];
    }
  | {
      description?: ReactNode;
      forwardingStatus: ForwardingStatusProps["status"];
    };

export function ErrorView(props: ErrorViewProps) {
  const { translate } = useLocales();
  return (
    <InformativeView
      {...props}
      imgSrc={errorImage}
      title={translate("common.errors.errorScreenTitle")}
    />
  );
}
