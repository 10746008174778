import { Typography } from "@mui/material";
import { useLocales } from "../../hooks";
import { Badge } from "./Badge.tsx";
import { BadgeInformationWrapper, BadgesList } from "./styled.ts";
import { ScrollableHeightContainer } from "../DynamicHeightContainer";
import { GamificationSummary } from "../../api";

interface BadgeInformationProps {
  badges: GamificationSummary["recent_badges"];
}

export function BadgeInformation({ badges }: BadgeInformationProps) {
  const { translate } = useLocales();
  return (
    <BadgeInformationWrapper>
      <Typography fontSize="14px" fontWeight="600" color="text.primary">
        {translate("assessment.recentBadges.title")}
      </Typography>
      <BadgesList>
        <ScrollableHeightContainer count={3}>
          {(elementRefs) => (
            <>
              {badges?.map((badge, index) =>
                badge.kind ? (
                  <Badge
                    key={badge.id}
                    kind={badge.kind}
                    badgeDate={badge.date_created}
                    ref={(el) => (elementRefs.current[index] = el)}
                  />
                ) : null,
              )}
            </>
          )}
        </ScrollableHeightContainer>
      </BadgesList>
    </BadgeInformationWrapper>
  );
}
