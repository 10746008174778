export const ReportEmailResults = {
  ASSESSMENT_REPORT: {
    title: "assessment.goodJob.suspiciousSubtitle",
  },
  ATTACK_ASSESSMENT: {
    title: "assessment.goodJob.simulatedSubtitle",
  },
  INCIDENT_REPORT: {
    title: "assessment.goodJob.incidentSubtitle",
  },
  ATTACK_INCIDENT: {
    title: "assessment.goodJob.incidentSubtitle",
  },
  ATTACK_PASS: {
    title: "",
  },
  ASSIGNMENT_SUBMISSION: {
    title: "",
  },
} as const;
