import { RewardEvent, RewardEventType } from "./types.ts";

const eventPriority: Record<RewardEventType, number> = {
  POINTS: 1,
  BADGE: 2,
  LEVEL_UP: 3,
};

export const sortRewardEvents = (events: RewardEvent[]): RewardEvent[] => {
  return [...events].sort((a, b) => {
    return eventPriority[a.type] - eventPriority[b.type];
  });
};
