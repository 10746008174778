import { defaultLocale, resources } from "./constants.ts";
import { BackendLocale } from "../api";

export function getCurrentLocale() {
  if (Office?.context?.displayLanguage) {
    return Office.context.displayLanguage;
  } else if (navigator.language) {
    return navigator.language;
  }
  return defaultLocale;
}

export function getShortenedLocale(locale: string) {
  return locale.split("-")[0];
}

const supportedLocales = Object.keys(resources);
export function getCurrentCachedLocale() {
  const locale = getCurrentLocale();
  const language = getShortenedLocale(locale);
  if (!supportedLocales.includes(language)) {
    return defaultLocale;
  }
  return language;
}

export function adaptLocaleToServerValue(locale: string): BackendLocale {
  if (locale === "ja") {
    return "jp";
  }
  if (!supportedLocales.includes(locale)) {
    return defaultLocale as BackendLocale;
  }
  return locale as BackendLocale;
}

export function getAdaptedCacheLocale() {
  const currentLocale = getCurrentCachedLocale();
  return adaptLocaleToServerValue(currentLocale);
}
