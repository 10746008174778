import { Box, Button } from "@mui/material";
import { useLocales } from "../../hooks";
import { useHistory } from "react-router-dom";

interface BadgeContentActionsProps {
  hasNext: boolean;
  showNextEvent: () => void;
}

const dashboardUrl = import.meta.env.VITE_DASHBOARD_URL;

export function BadgeContentActions({ hasNext, showNextEvent }: BadgeContentActionsProps) {
  const { translate } = useLocales();
  const history = useHistory();

  const handleViewDashboard = () => {
    window.open(dashboardUrl, "_blank");
  };

  const handleClose = () => {
    history.goBack();
  };

  if (hasNext) {
    return (
      <Button variant="contained" fullWidth onClick={showNextEvent}>
        {translate("common.next")}
      </Button>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Button variant="contained" fullWidth onClick={handleViewDashboard}>
        {translate("assessment.badges.viewDashboard")}
      </Button>
      <Button variant="outlined" fullWidth onClick={handleClose}>
        {translate("common.close")}
      </Button>
    </Box>
  );
}
