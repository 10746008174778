import { EarnedBadge } from "./EarnedBadge.tsx";
import { ReportEmailCongratulation } from "../../components/ReportEmailCongratulation";
import { NewLevel } from "../../components/NewLevel";
import { RewardEvent } from "../../hooks/employeeAdvancement/types.ts";

export interface EarningsContentProps {
  currentEvent: RewardEvent;
}

export function EarningsContent({ currentEvent }: EarningsContentProps) {
  const badgeKind = currentEvent?.badge?.kind;

  switch (currentEvent?.type) {
    case "POINTS": {
      return <ReportEmailCongratulation earning={currentEvent} />;
    }
    case "LEVEL_UP": {
      return <NewLevel badge={currentEvent.badge} />;
    }
    case "BADGE": {
      return <EarnedBadge badgeKind={badgeKind} badgeDate={currentEvent.badge?.badge_date} />;
    }
    default: {
      return <EarnedBadge badgeKind={badgeKind} badgeDate={currentEvent.badge?.badge_date} />;
    }
  }
}
