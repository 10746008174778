import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

function createHttpClient() {
  return axios.create({
    baseURL: "/api/",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export const httpClient = createHttpClient();

export async function makeHttpRequest<SuccessPayload>(
  config: AxiosRequestConfig
): Promise<AxiosResponse<SuccessPayload>> {
  return httpClient.request<SuccessPayload>(config);
}
