import { Button, Grid, Typography } from "@mui/material";
import { useLocales } from "../../hooks";
import { LoadingButton } from "@mui/lab";
import { FormEvent } from "react";
import { routes } from "../../settings/routes.ts";
import { useHistory, useLocation } from "react-router-dom";
import { FeedbackRadioGroup } from "./FeedbackRadioGroup.tsx";
import { StyledBlock, StyledForm, StyledWrapper, TextArea, TypographySecondary } from "./styled.ts";
import { useReportFeedback } from "./useReportFeedback.ts";
import { PostApiAssessmentReportByMessageIdFeedbackApiArg } from "../../api";

const FEEDBACK_FORM_ID = "feedbackForm";

function ProvideFeedbackScreen() {
  const { translate } = useLocales();
  const history = useHistory();
  const location = useLocation();

  const state = location.state as { messageId?: string };
  const messageId = state?.messageId ?? "";

  const { mutate, isLoading } = useReportFeedback();
  const submitFeedback = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const level = formData.get(
      "radioFeedback",
    ) as PostApiAssessmentReportByMessageIdFeedbackApiArg["data"]["level"];

    mutate({
      messageId,
      data: {
        level,
        user_message: String(formData.get("feedback")),
      },
    });
  };

  const handleBack = () => {
    history.push(routes.assessment.index);
  };

  return (
    <StyledWrapper>
      <Typography fontSize="20px" fontWeight="600" color="text.primary">
        {translate("assessment.provideFeedback.title")}
      </Typography>
      <StyledForm id={FEEDBACK_FORM_ID} onSubmit={submitFeedback}>
        <StyledBlock>
          <TypographySecondary>
            {translate("assessment.provideFeedback.subtitle")}
          </TypographySecondary>
          <FeedbackRadioGroup />
        </StyledBlock>
        <StyledBlock>
          <TypographySecondary>
            {translate("assessment.provideFeedback.description")}
          </TypographySecondary>
          <TextArea name="feedback" multiline rows={4} />
        </StyledBlock>
      </StyledForm>
      <Grid container width="100%" justifyContent="center" flexWrap="nowrap">
        <Button
          variant="outlined"
          sx={{ mr: "16px", p: "8px 16px", flexShrink: 0 }}
          onClick={handleBack}
        >
          {translate("common.back")}
        </Button>
        <LoadingButton
          loading={isLoading}
          form={FEEDBACK_FORM_ID}
          type="submit"
          variant="contained"
          fullWidth
        >
          {translate("common.submit")}
        </LoadingButton>
      </Grid>
    </StyledWrapper>
  );
}

export default ProvideFeedbackScreen;
