import { useQuery } from "react-query";
import { apiHandlers, UserInfo } from "../api";
import { AxiosError } from "axios";
import { useLoginContext } from "../api/loginContext";
import { adaptLocaleToServerValue, getCurrentLocale, getShortenedLocale } from "../locales";

export function useUserInfoQuery() {
  const { accessToken, markIdentityAPIFailed } = useLoginContext();
  const locale = getCurrentLocale();
  const shortenedLocale = getShortenedLocale(locale);

  const language = adaptLocaleToServerValue(shortenedLocale ?? "");

  return useQuery<UserInfo, AxiosError>(
    ["userInfo", accessToken],
    () => {
      return apiHandlers.info
        .getUserInfo({
          headers: {
            Authorization: `${accessToken}`,
          },
          params: {
            language,
          },
        })
        .then((response) => response.data)
        .catch((error) => {
          console.log("Error while fetching user info", error);
          if (error.response?.status === 400) {
            markIdentityAPIFailed?.();
          }
          return Promise.reject(error);
        });
    },
    {
      enabled: !!accessToken,
      cacheTime: 10 * 60 * 1000,
      staleTime: 10 * 60 * 1000,
    },
  );
}
