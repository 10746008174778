import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

export interface ProgressProps {
  message: string;
}

export function Progress({ message }: ProgressProps) {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        transform: "translateY(25%)",
        textAlign: "center",
      }}
    >
      <CircularProgress sx={{ mb: "30px" }} />
      <Typography variant="h2">{message}</Typography>
    </Box>
  );
}
