import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { GeneralLayout } from "./components/GeneralLayout";
import {
  MainScreen,
  AssessmentScreen,
  IncidentScreen,
  NotFoundScreen,
  ThankYouScreen,
} from "./screens";
import { routes } from "./settings/routes";
import { LoginProvider } from "./api/loginProvider";
import ProvideFeedbackScreen from "./screens/ProvideFeedbackScreen";
import BadgeResultScreen from "./screens/BadgeResultScreen";
import { isFeatureEnabled } from "./featureFlags";
import { GamificationSummaryProvider } from "./contexts/GamificationSummary/GamificationSummaryProvider.tsx";
import { useClearSessionStorageOnClose } from "./hooks/useClearSessionStorageOnClose.ts";

// So far Outlook does not work with react-router 6 and above. Please, do not upgrade, until the Office.js is fixed.
// Also Routing itself is quite troublesome in Office.js, so we use HashRouter instead of BrowserRouter
export interface AppProps {
  isOfficeInitialized: boolean;
}

function App({ isOfficeInitialized }: AppProps) {
  useClearSessionStorageOnClose();
  return (
    <LoginProvider>
      <GamificationSummaryProvider>
        <Router>
          <Switch>
            <Route exact path={routes.root}>
              <GeneralLayout
                sx={{ justifyContent: "center" }}
                isOfficeInitialized={isOfficeInitialized}
              >
                <MainScreen />
              </GeneralLayout>
            </Route>
            <Route exact path={routes.assessment.index}>
              <GeneralLayout sx={{ pt: "45px" }} isOfficeInitialized={isOfficeInitialized}>
                <AssessmentScreen />
              </GeneralLayout>
            </Route>
            <Route exact path={routes.assessment.provideFeedback.index}>
              <GeneralLayout sx={{ pt: "45px" }} isOfficeInitialized={isOfficeInitialized}>
                <ProvideFeedbackScreen />
              </GeneralLayout>
            </Route>
            <Route exact path={routes.assessment.thankYou.index}>
              <GeneralLayout sx={{ pt: "45px" }} isOfficeInitialized={isOfficeInitialized}>
                <ThankYouScreen />
              </GeneralLayout>
            </Route>
            <Route exact path={routes.incident.index}>
              <GeneralLayout
                sx={{ justifyContent: "center", flexWrap: "nowrap" }}
                isOfficeInitialized={isOfficeInitialized}
              >
                <IncidentScreen />
              </GeneralLayout>
            </Route>
            {isFeatureEnabled("PS-Gamification2-Rewards") ? (
              <Route exact path={routes.result.badge.index}>
                <GeneralLayout sx={{ pt: "57px" }} isOfficeInitialized={isOfficeInitialized}>
                  <BadgeResultScreen />
                </GeneralLayout>
              </Route>
            ) : null}

            <Route path="*">
              <NotFoundScreen />
            </Route>
          </Switch>
        </Router>
      </GamificationSummaryProvider>
    </LoginProvider>
  );
}

export default App;
