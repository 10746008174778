import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { useLocales } from "../../hooks";

import { IconWithConfetti } from "./IconWithConfetti.tsx";
import { EmployeeEarning } from "../../api";
import { ReportEmailResults } from "./consts.ts";

export const TextContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  textAlign: "center",
  gap: "12px",
  marginTop: "40px",
});

interface ReportEmailCongratulationProps {
  earning: EmployeeEarning;
}

export function ReportEmailCongratulation({ earning }: ReportEmailCongratulationProps) {
  const { translate } = useLocales();
  const reportEmailKind = earning.score?.kind;
  if (!reportEmailKind) return null;

  const reportEmailTitle = ReportEmailResults[reportEmailKind].title;
  return (
    <Box>
      <IconWithConfetti />
      <TextContent>
        <Typography color="text.primary" fontSize="20px" fontWeight="700">
          {translate("assessment.goodJob.title")}
        </Typography>
        <Typography color="text.secondary" fontSize="16px" fontWeight="400" lineHeight="normal">
          {reportEmailTitle ? translate(reportEmailTitle) : null}
        </Typography>
        <Typography color="#0057FF" fontSize="16px" fontWeight="400" lineHeight="normal">
          {translate("assessment.goodJob.points", {
            points: earning.score?.score,
          })}
        </Typography>
      </TextContent>
    </Box>
  );
}
