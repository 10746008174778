import { Box } from "@mui/material";

import ConfettiGif from "../../assets/493e69b0c49d839a16fe76f3c2670c87.gif";
import CheckIcon from "../../assets/Check.svg?react";

import { styled } from "@mui/material/styles";

const StyledConfettiGif = styled("img")({
  position: "absolute",
  top: "43%",
  left: "50%",
  zIndex: 0,
  width: "100%",
  objectFit: "cover",
  overflow: "hidden",
  transform: "translate(-50%, -43%)",
});

const StyledCheckIcon = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  position: "relative",
  zIndex: 1,
});

export function IconWithConfetti() {
  return (
    <Box position="relative">
      <StyledCheckIcon>
        <CheckIcon />
      </StyledCheckIcon>
      <StyledConfettiGif src={ConfettiGif} />
    </Box>
  );
}
