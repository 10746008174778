import { getData, postData } from "./crudHandlers";
import { apiPaths } from "./apiPaths";
import {
  AssessmentRequestData,
  AssessmentResponseData,
  UserInfo,
} from "./types";
import { AxiosRequestConfig } from "axios";

export const apiHandlers = {
  info: {
    getUserInfo(config?: AxiosRequestConfig) {
      return getData<UserInfo>(apiPaths.info.getUserInfo, config);
    },
  },
  assessment: {
    check(data: AssessmentRequestData, config?: AxiosRequestConfig) {
      return postData<AssessmentResponseData>(apiPaths.assessment.check, {
        ...config,
        data,
      });
    },
  },
} as const;
