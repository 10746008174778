import { Button, Grid, styled, Typography, TextField, InputLabel } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import imgUrl from "../../assets/main-logo.png";
import { InformativeView } from "../../components/InformativeView";
import { ErrorView } from "../../components/ErrorView.tsx";
import secureLaptopImg from "../../assets/secure-laptop.png";
import { useIncidentHandlers } from "./useIncidentHandlers.ts";
import wellDoneImage from "../../assets/well-done.png";
import { isFeatureEnabled } from "../../featureFlags";
import { useUserInfoQuery } from "../../hooks/useUserInfoQuery.ts";
import { Progress } from "../../components/Progress.tsx";

const MainLogo = styled("img")`
  width: 100%;
  max-width: 180px;
  margin-bottom: 70px;
`;

const TextArea = styled(TextField)`
  width: 100%;
`;

const IncidentForm = styled("form")`
  width: 100%;
  margin-bottom: 24px;
`;

export function IncidentScreen() {
  const { data: infoData } = useUserInfoQuery();
  const { translate, goBack, currentReportStatus, submitReport, submitStatus } =
    useIncidentHandlers();

  switch (currentReportStatus) {
    case "loading":
      return <Progress message="" />;
    case "success":
      return (
        <InformativeView
          imgSrc={secureLaptopImg}
          title={translate("incident.successResultTitle")}
          description={infoData?.config?.email_report_message}
          forwardingStatus={currentReportStatus}
        />
      );
    case "error":
      return <ErrorView forwardingStatus={currentReportStatus} />;
    case "bypass":
      return (
        <InformativeView
          imgSrc={wellDoneImage}
          title={translate("assessment.internalAssessment.title")}
          description={
            infoData?.config?.report_completion_text ||
            translate("assessment.internalAssessment.description")
          }
        />
      );
    default:
      return (
        <>
          <MainLogo src={imgUrl} alt="AironWorks logo" />
          <Typography variant="h1" mb="24px">
            {translate("incident.title")}
          </Typography>

          <IncidentForm id="incidentForm" onSubmit={submitReport}>
            <InputLabel
              htmlFor="incidentMessage"
              sx={{ fontSize: "12px", color: "text.secondary", mb: "8px" }}
            >
              {isFeatureEnabled("PS-AIGeneratedAttacks")
                ? translate("incident.descriptionLabelNew")
                : translate("incident.descriptionLabel")}
            </InputLabel>
            <TextArea name="incident" id="incidentMessage" multiline rows={4} />
          </IncidentForm>

          <Grid container width="100%" justifyContent="center" flexWrap="nowrap">
            <Button
              variant="outlined"
              sx={{ mr: "16px", p: "8px 16px", flexShrink: 0 }}
              onClick={goBack}
            >
              {translate("common.cancel")}
            </Button>
            <LoadingButton
              loading={submitStatus === "loading"}
              form="incidentForm"
              type="submit"
              variant="contained"
              fullWidth
            >
              {translate("common.submit")}
            </LoadingButton>
          </Grid>
        </>
      );
  }
}
