export const apiPaths = {
  info: {
    getUserInfo: "/assessment/info",
    getBadgeData: "/assessment/gamification-summary",
  },
  assessment: {
    check: "/assessment/assessment_outlook",
    reportFeedback: "/assessment/report/{messageId}/feedback",
  },
} as const;
