import axios from "axios";

function prepareEWSRequest(id: string): string {
  return `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:xsi="https://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="https://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types">
      <soap:Header>
        <RequestServerVersion Version="Exchange2013" xmlns="http://schemas.microsoft.com/exchange/services/2006/types" soap:mustUnderstand="0" />
      </soap:Header>
      <soap:Body>
        <GetItem xmlns="http://schemas.microsoft.com/exchange/services/2006/messages">
          <ItemShape>
             <t:AdditionalProperties>
               <t:FieldURI FieldURI="item:MimeContent" />
               <t:FieldURI FieldURI="item:Subject" />
             </t:AdditionalProperties>
          </ItemShape>
          <ItemIds><t:ItemId Id="${id}"/></ItemIds>
        </GetItem>
      </soap:Body>
    </soap:Envelope>`;
}

function getEmailMimeResponse(ews: string) {
  return new Office.Promise((resolve) => {
    Office.context.mailbox.makeEwsRequestAsync(ews, (result) => {
      resolve(result);
    });
    Office.context.mailbox.getUserIdentityTokenAsync;
  });
}

function adaptMimeContentResponse(res: Office.AsyncResult<string>): string {
  if (res.status.toString() === "succeeded") {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(res.value, "text/xml");
    try {
      return xmlDoc.getElementsByTagName("t:MimeContent")[0].innerHTML;
    } catch {
      const errorStr = xmlDoc.getElementsByTagName("faultstring")[0].innerHTML;
      throw new Error(errorStr);
    }
  } else {
    throw new Error("could not get mail mime-content");
  }
}

export async function getOutlookEmailMimeContent(accessToken?: string | null) {
  const id = Office.context.mailbox?.item?.itemId;
  if (id) {
    if (accessToken) {
      const response = axios.get(
        "https://graph.microsoft.com/v1.0/me/messages/" +
          Office.context.mailbox.convertToRestId(
            id,
            Office.MailboxEnums.RestVersion.v1_0
          ) +
          "/$value",
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      const mimeContent = (await response).data;
      return btoa(mimeContent);
    } else {
      // legacy
      const ewsReq = prepareEWSRequest(id);
      const response = await getEmailMimeResponse(ewsReq);
      return adaptMimeContentResponse(response as Office.AsyncResult<string>);
    }
  } else {
    return Promise.reject("NO ID FOUND FOR EMAIL MIME CONTENT");
  }
}

export async function sendMail(
  accessToken: string,
  subject: string,
  body: string,
  to: string,
  mail: string
) {
  const response = await axios.post(
    "https://graph.microsoft.com/v1.0/me/sendMail",
    {
      message: {
        subject: subject,
        body: {
          contentType: "HTML",
          content: body,
        },
        toRecipients: [{ emailAddress: { address: to } }],
        attachments: [
          {
            "@odata.type": "#microsoft.graph.fileAttachment",
            name: "mail.eml",
            contentType: "message/rfc822",
            contentBytes: mail,
          },
        ],
      },
    },
    {
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    }
  );
  return response;
}

type Message = {
  subject: string;
  body: {
    contentType: "HTML"; // Since this is a fixed string value
    content: string;
  };
  toRecipients: {
    emailAddress: {
      address: string;
    };
  }[];
};

export async function sendLargeMail(
  accessToken: string,
  subject: string,
  body: string,
  to: string,
  mail: string
) {
  const message: Message = {
    subject: subject,
    body: {
      contentType: "HTML",
      content: body,
    },
    toRecipients: [{ emailAddress: { address: to } }],
  };
  const parsedMail = atob(mail);
  const { sessionUrl, messageId } = await createUploadSession(
    accessToken,
    message,
    parsedMail
  );
  await uploadAttachment(sessionUrl, parsedMail);

  return await axios.post(
    `https://graph.microsoft.com/v1.0/me/messages/${messageId}/send`,
    undefined,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    }
  );
}

const createUploadSession = async (
  accessToken: string,
  message: Message,
  content: string
) => {
  const response = await axios.post(
    "https://graph.microsoft.com/v1.0/me/messages",
    {
      ...message,
    },
    {
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    }
  );

  const uploadSession = await axios.post(
    `https://graph.microsoft.com/v1.0/me/messages/${response.data.id}/attachments/createUploadSession`,
    {
      AttachmentItem: {
        attachmentType: "file",
        contentType: "message/rfc822",
        name: "mail.eml",
        size: content.length,
      },
    },
    {
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    }
  );

  return {
    sessionUrl: uploadSession.data.uploadUrl,
    messageId: response.data.id,
  };
};

const uploadAttachment = async (uploadUrl: string, content: string) => {
  const chunkSize = 320 * 1024;
  const fileSize = content.length;
  let start = 0;
  let end = chunkSize < fileSize ? chunkSize : fileSize;

  while (start < fileSize) {
    const form = new FormData();
    const chunk = content.slice(start, end);

    form.append("file", chunk);

    await axios.put(uploadUrl, chunk, {
      headers: {
        "Content-Range": `bytes ${start}-${end - 1}/${fileSize}`,
      },
    });

    start = end;
    end = start + chunkSize;
    if (end > fileSize) end = fileSize;
  }
};

export function getMessageId() {
  return Office.context.mailbox.convertToRestId(
    Office.context.mailbox?.item?.itemId ?? "",
    Office.MailboxEnums.RestVersion.v1_0
  );
}
