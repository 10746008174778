import { useLocales } from "../../hooks";
import { useHistory } from "react-router-dom";
import { useReportMutation } from "../../hooks/useReportMutation.ts";
import { routes } from "../../settings/routes.ts";
import { FormEvent } from "react";
import { AssessmentResponseData } from "../../api";
import { calculateAssessmentStatus } from "../../utils/calculateAssessmentStatus.ts";

type CurrentReportStatus = ReturnType<typeof calculateAssessmentStatus> | "initial";

function calculateReportStatus(
  status: ReturnType<typeof useReportMutation>["status"],
  data?: AssessmentResponseData,
): CurrentReportStatus {
  const isEmailAlreadyReported = sessionStorage.getItem("isEmailAlreadyReported") === "true";
  const assessmentResult = JSON.parse(sessionStorage.getItem("assessmentResult") || "{}");

  if (!isEmailAlreadyReported && status === "idle") {
    return "initial";
  }

  if (Object.keys(assessmentResult).length) {
    return calculateAssessmentStatus(assessmentResult, "DONE");
  }

  return calculateAssessmentStatus(data?.assessment_result, data?.status);
}

export function useIncidentHandlers() {
  const { translate } = useLocales();
  const history = useHistory();

  const { mutate, status, data: response } = useReportMutation();

  const data = response?.data;

  const currentReportStatus: CurrentReportStatus = calculateReportStatus(status, data);

  const goBack = () => {
    history.push(routes.root);
  };

  const submitReport = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (event.target instanceof HTMLFormElement) {
      mutate({
        user_message: (event.target.elements[0] as HTMLTextAreaElement)?.value as string,
      });
    }
  };

  return {
    translate,
    goBack,
    currentReportStatus: currentReportStatus as CurrentReportStatus,
    submitReport,
    submitStatus: status,
  };
}
