import { ErrorView } from "../../components/ErrorView.tsx";
import { ExternalAssessmentView } from "../../components/Assessment/ExternalAssessmentView.tsx";
import { InformativeView } from "../../components/InformativeView";
import wellDoneImage from "../../assets/well-done.png";
import { useAssessmentData } from "./useAssessmentData.ts";
import { LoadingScreen } from "../../components/LoadingScreen.tsx";
import { useUserInfoQuery } from "../../hooks/useUserInfoQuery.ts";
import { WithBadgeInformation } from "../../components/Badges/WithBadgeInformation.tsx";
import { isFeatureEnabled } from "../../featureFlags";

export function AssessmentScreen() {
  const { data: infoData } = useUserInfoQuery();
  const { translate, assessmentStatus, forwardStatus, data } = useAssessmentData();

  const isShowBadges =
    isFeatureEnabled("PS-AssessmentExperienceUpgrade") && infoData?.show_assessment_loading_stats;

  switch (assessmentStatus) {
    case "loading":
      return (
        <WithBadgeInformation isShowBadges={isShowBadges}>
          <LoadingScreen />
        </WithBadgeInformation>
      );
    case "error":
      return (
        <ErrorView
          description={translate("common.errors.unavailableAssessment")}
          forwardingStatus={forwardStatus}
        />
      );
    case "bypass":
      return (
        <InformativeView
          imgSrc={wellDoneImage}
          title={translate("assessment.internalAssessment.title")}
          description={
            infoData?.config?.report_completion_text ||
            translate("assessment.internalAssessment.description")
          }
        />
      );
    default:
      return (
        <ExternalAssessmentView
          riskLevel={data?.assessment?.level || "LOW"}
          forwardStatus={forwardStatus}
          assessmentDetails={data?.assessment?.details ?? []}
          messageId={String(data?.message_id)}
        />
      );
  }
}
