import { motion } from "framer-motion";
// material
import { alpha } from "@mui/material/styles";
import { Box, Grid } from "@mui/material";
//
import Logo from "./Logo";

export function LoadingAnimation({ scale = 1 }) {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ position: "relative", width: 120 * scale, height: 120 * scale }}
    >
      <Grid item>
        <motion.div
          initial={{ rotateY: 0 }}
          animate={{ rotateY: 360 }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            repeatDelay: 1,
            repeat: Infinity,
          }}
        >
          <Logo sx={{ width: 64 * scale, height: 64 * scale }} />
        </motion.div>
      </Grid>

      <Box
        component={motion.div}
        animate={{
          scale: [1.2, 1, 1, 1.2, 1.2],
          rotate: [270, 0, 0, 270, 270],
          opacity: [0.25, 1, 1, 1, 0.25],
          borderRadius: ["25%", "25%", "50%", "50%", "25%"],
        }}
        transition={{ ease: "linear", duration: 3.2, repeat: Infinity }}
        sx={{
          width: 100 * scale,
          height: 100 * scale,
          borderRadius: "25%",
          position: "absolute",
          left: "8%",
          top: "8%",
          border: (theme) =>
            `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
        }}
      />

      <Box
        component={motion.div}
        animate={{
          scale: [1, 1.2, 1.2, 1, 1],
          rotate: [0, 270, 270, 0, 0],
          opacity: [1, 0.25, 0.25, 0.25, 1],
          borderRadius: ["25%", "25%", "50%", "50%", "25%"],
        }}
        transition={{
          ease: "linear",
          duration: 3.2,
          repeat: Infinity,
        }}
        sx={{
          width: 120 * scale,
          height: 120 * scale,
          borderRadius: "25%",
          position: "absolute",
          border: (theme) =>
            `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
          top: "0",
          left: "0",
        }}
      />
    </Grid>
  );
}
