import { getBadgeData } from "./consts.tsx";
import { Typography } from "@mui/material";
import { useLocales } from "../../hooks";
import { format } from "date-fns";
import { BadgeWrapper, IconWrapper, StyledContent } from "./styled.ts";
import { forwardRef } from "react";
import { EmployeeEarningBadge } from "../../api";

interface BadgeProps {
  kind: EmployeeEarningBadge["kind"];
  badgeDate?: number;
}

export const Badge = forwardRef<HTMLDivElement, BadgeProps>(({ kind, badgeDate }, ref) => {
  const { translate } = useLocales();
  const badgeData = getBadgeData(kind);

  return badgeData ? (
    <BadgeWrapper ref={ref}>
      <IconWrapper>{badgeData?.icon}</IconWrapper>
      <StyledContent>
        <Typography color="text.primary" fontSize="14" fontWeight="600">
          {translate(badgeData.title)}
        </Typography>
        <Typography color="text.secondary" fontSize="14" fontWeight="400" lineHeight="normal">
          {translate(badgeData.subtitle, {
            date: format(new Date((badgeDate ?? 0) * 1000), "MMMM yyyy"),
          })}
        </Typography>
      </StyledContent>
    </BadgeWrapper>
  ) : null;
});
