import { createContext, useContext } from "react";
import { GamificationSummary } from "../../api";

interface GamificationSummaryState {
  gamificationSummary: GamificationSummary | null;
}

export const gamificationSummaryDefault: GamificationSummaryState = {
  gamificationSummary: null,
};

export const GamificationSummaryContext = createContext<GamificationSummaryState>(
  gamificationSummaryDefault,
);

export function useGamificationSummary() {
  const contextValue = useContext<GamificationSummaryState>(GamificationSummaryContext);
  if (!contextValue) {
    throw new Error(`useGamificationSummary must be used within GamificationSummaryContext`);
  }
  return contextValue;
}
