import { FC, useMemo, memo, useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import {
  GRADIENT_ID,
  HASH_MARK_COUNT,
  HASH_MARK_POSITIONS,
  RISK_LEVELS,
  RISK_LEVEL_COLOR_MAP,
  HASH_MARK_GAP,
  HASH_MARK_WIDTH,
  HASH_MARK_HEIGHT,
} from "./constants";
import { RiskLevel, RiskMeterProps } from "./types";
import { calculateOpacity } from "./utils";
import { Typography, Box, Grid } from "@mui/material";
import { useLocales } from "../../hooks";
import camelCase from "lodash/camelCase";

const GaugeGradient: FC = memo(() => (
  <defs>
    {RISK_LEVELS.map((level, index) => (
      <linearGradient
        key={level}
        id={`${GRADIENT_ID}-${level}`}
        gradientUnits="userSpaceOnUse"
        x1="0"
        y1="95"
        x2="190"
        y2="95"
      >
        {RISK_LEVELS.slice(0, index + 1).map((level, i) => {
          const targetOffset = index === 0 ? i * 100 : (i * 100) / index;
          return (
            <stop
              key={level}
              offset={`${targetOffset}%`}
              stopColor={RISK_LEVEL_COLOR_MAP[level]}
            />
          );
        })}
      </linearGradient>
    ))}
  </defs>
));

const GaugeArc: FC<{ riskLevel: RiskLevel }> = memo(({ riskLevel }) => {
  const targetHashMark = useMemo(
    () => HASH_MARK_POSITIONS[riskLevel],
    [riskLevel]
  );
  const targetArcLength = useMemo(
    () => (180 / (HASH_MARK_COUNT - 1)) * targetHashMark,
    [targetHashMark]
  );

  const { arcLength } = useSpring({
    arcLength: targetArcLength,
    config: { duration: 300 },
  });

  return (
    <animated.path
      aria-label="Gauge Arc"
      d={arcLength.to((arcLength) => {
        const endX = 95 + 80 * Math.cos((arcLength * Math.PI) / 180 - Math.PI);
        const endY = 95 + 80 * Math.sin((arcLength * Math.PI) / 180 - Math.PI);
        const largeArcFlag = arcLength > 180 ? 1 : 0;

        return `M 15,95 A 80,80 0 ${largeArcFlag},1 ${endX},${endY}`;
      })}
      fill="none"
      stroke={`url(#${GRADIENT_ID}-${riskLevel})`}
      strokeWidth="20"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
});

const GaugeHashMarks: FC<{ riskIndex: number }> = memo(({ riskIndex }) => {
  const hashMarks = useMemo(() => {
    return Array.from({ length: HASH_MARK_COUNT }, (_, i) => {
      const angle = (180 / (HASH_MARK_COUNT - 1)) * i;
      const x =
        95 + (80 - HASH_MARK_GAP) * Math.cos((angle * Math.PI) / 180 - Math.PI);
      const y =
        95 + (80 - HASH_MARK_GAP) * Math.sin((angle * Math.PI) / 180 - Math.PI);
      const riskLevelIndex = Math.floor(
        (i * (RISK_LEVELS.length - 1)) / (HASH_MARK_COUNT - 1)
      );
      const color = RISK_LEVEL_COLOR_MAP[RISK_LEVELS[riskLevelIndex]];
      const opacity = calculateOpacity(riskIndex, i);

      return (
        <rect
          aria-label="Hash Mark"
          key={i}
          x={x - HASH_MARK_WIDTH / 2}
          y={y}
          width={HASH_MARK_WIDTH}
          height={HASH_MARK_HEIGHT}
          fill={color}
          fillOpacity={opacity}
          transform={`rotate(${angle - 90}, ${x}, ${y})`}
        />
      );
    });
  }, [riskIndex]);

  return <>{hashMarks}</>;
});

const GaugeNeedle: FC<{ riskPercentage: number }> = ({ riskPercentage }) => {
  const { angle } = useSpring({
    angle: riskPercentage,
    config: { mass: 3, tension: 330, friction: 25 },
  });

  return (
    <>
      <animated.path
        aria-label="Gauge Needle"
        d={angle.to((a) => {
          const x =
            95 + 60 * Math.cos((((a * 180) / 100) * Math.PI) / 180 - Math.PI);
          const y =
            95 + 60 * Math.sin((((a * 180) / 100) * Math.PI) / 180 - Math.PI);
          const x1 =
            95 +
            3 * Math.cos((((a * 180) / 100 + 90) * Math.PI) / 180 - Math.PI);
          const y1 =
            95 +
            3 * Math.sin((((a * 180) / 100 + 90) * Math.PI) / 180 - Math.PI);
          const x2 =
            95 +
            3 * Math.cos((((a * 180) / 100 - 90) * Math.PI) / 180 - Math.PI);
          const y2 =
            95 +
            3 * Math.sin((((a * 180) / 100 - 90) * Math.PI) / 180 - Math.PI);
          return `M ${x},${y} L ${x1},${y1} L ${x2},${y2} Z`;
        })}
        fill={angle.to((value) =>
          value <= 0
            ? RISK_LEVEL_COLOR_MAP["LOW"]
            : value > 100
            ? RISK_LEVEL_COLOR_MAP["HIGH"]
            : RISK_LEVEL_COLOR_MAP[
                RISK_LEVELS[
                  Math.floor((value * (RISK_LEVELS.length - 1)) / 100)
                ]
              ]
        )}
      />
      <circle cx="95" cy="95" r="8" fill="#ffffff" />
      <animated.circle
        aria-label="Gauge Needle base"
        cx="95"
        cy="95"
        r="8"
        fill={angle.to((value) =>
          value <= 0
            ? RISK_LEVEL_COLOR_MAP["LOW"]
            : value > 100
            ? RISK_LEVEL_COLOR_MAP["HIGH"]
            : RISK_LEVEL_COLOR_MAP[
                RISK_LEVELS[
                  Math.floor((value * (RISK_LEVELS.length - 1)) / 100)
                ]
              ]
        )}
      />
    </>
  );
};

export const RiskMeter: FC<RiskMeterProps> = ({ riskLevel }) => {
  //   TODO: remove internal risk state and useEffect. Currently it is used to trigger animation on component render
  const [internalRiskLevel, setInternalRiskLevel] = useState<RiskLevel>("LOW");
  useEffect(() => {
    if (internalRiskLevel !== riskLevel) {
      setInternalRiskLevel(riskLevel);
    }
  }, [riskLevel, internalRiskLevel]);

  const { translate } = useLocales();
  const riskIndex = useMemo(
    () => RISK_LEVELS.indexOf(internalRiskLevel),
    [internalRiskLevel]
  );
  const riskPercentage = useMemo(
    () => (riskIndex * 100) / (RISK_LEVELS.length - 1),
    [riskIndex]
  );

  return (
    <Grid container flexDirection="column" alignItems="center">
      <Box mb="16px">
        <svg
          width="190"
          height="110"
          viewBox="0 0 190 110"
          xmlns="http://www.w3.org/2000/svg"
        >
          <GaugeGradient />
          <GaugeArc riskLevel={internalRiskLevel} />
          <GaugeHashMarks riskIndex={riskIndex} />
          <GaugeNeedle riskPercentage={riskPercentage} />
        </svg>
      </Box>
      <Typography
        fontSize="18px"
        fontWeight="700"
        textTransform="uppercase"
        color={RISK_LEVEL_COLOR_MAP[internalRiskLevel]}
        mb="8px"
      >
        {translate(
          `assessment.riskLevels.${camelCase(internalRiskLevel) as "low"}.title`
        )}
      </Typography>
      <Typography variant="h2">
        {translate("assessment.riskLevels.title")}
      </Typography>
    </Grid>
  );
};
