import { GamificationSummaryContext } from "./GamificationSummaryContext.ts";
import { ReactNode } from "react";
import { isFeatureEnabled } from "../../featureFlags";
import { useUserInfoQuery } from "../../hooks/useUserInfoQuery.ts";
import { useBadgeData } from "../../hooks/useBadgeData.ts";
import { useLoginContext } from "../../api/loginContext.ts";

interface GamificationSummaryProviderProps {
  children: ReactNode;
}

const { Provider } = GamificationSummaryContext;

export function GamificationSummaryProvider({ children }: GamificationSummaryProviderProps) {
  const { accessToken } = useLoginContext();

  const { data: infoData } = useUserInfoQuery();
  const isShowBadges =
    isFeatureEnabled("PS-AssessmentExperienceUpgrade") && infoData?.show_assessment_loading_stats;

  const { data: gamificationSummary } = useBadgeData({
    enabled: !!accessToken && isShowBadges,
  });

  return (
    <Provider value={{ gamificationSummary: gamificationSummary ?? null }}>{children}</Provider>
  );
}
