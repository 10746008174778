import { ReactNode } from "react";
import { Box } from "@mui/material";
import { BadgeInformation } from "./BadgeInformation.tsx";
import { LevelInformation } from "./LevelInformation.tsx";
import { useGamificationSummary } from "../../contexts/GamificationSummary/GamificationSummaryContext.ts";

interface WithBadgeInformationProps {
  children: ReactNode;
  isShowBadges?: boolean;
}

export function WithBadgeInformation({
  children,
  isShowBadges = false,
}: WithBadgeInformationProps) {
  const { gamificationSummary } = useGamificationSummary();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
      {children}
      {isShowBadges ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
          <LevelInformation
            score={Number(gamificationSummary?.points)}
            currentLevel={gamificationSummary?.current_level}
            nextLevel={gamificationSummary?.next_level}
          />
          {gamificationSummary?.recent_badges ? (
            <BadgeInformation badges={gamificationSummary.recent_badges} />
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
}
