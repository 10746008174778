import { useQuery } from "react-query";
import { apiHandlers, UserInfo } from "../api";
import { AxiosError } from "axios";
import { useLoginContext } from "../api/loginContext";
import {
  adaptLocaleToServerValue,
  getCurrentLocale,
  getShortenedLocale,
} from "../locales";

export function useUserInfoQuery() {
  const { accessToken } = useLoginContext();
  const locale = getCurrentLocale();
  const shortenedLocale = getShortenedLocale(locale);

  const language = adaptLocaleToServerValue(shortenedLocale ?? "");

  return useQuery<UserInfo, AxiosError>(
    ["userInfo"],
    () => {
      return apiHandlers.info
        .getUserInfo({
          headers: {
            Authorization: `${accessToken}`,
          },
          params: {
            language,
          },
        })
        .then((response) => response.data);
    },
    {
      enabled: !!accessToken,
    }
  );
}
