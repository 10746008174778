import Box from "@mui/material/Box";
import { RiskMeter } from "./RiskMeter";
import {
  ForwardingStatus,
  ForwardingStatusProps,
} from "./ForwardingStatus.tsx";
import { Alert, Typography } from "@mui/material";
import { Trans } from "react-i18next";
import camelCase from "lodash/camelCase";
import {
  AssessmentDetails,
  AssessmentDetailsProps,
} from "./AssessmentDetails.tsx";
import { RiskLevel } from "./RiskMeter/types.ts";

interface ExternalAssessmentViewProps {
  riskLevel: RiskLevel;
  forwardStatus: ForwardingStatusProps["status"];
  assessmentDetails: AssessmentDetailsProps["data"];
}

export function ExternalAssessmentView({
  riskLevel,
  forwardStatus,
  assessmentDetails,
}: ExternalAssessmentViewProps) {
  return (
    <Box mb="16px">
      <Box mb="45px">
        <RiskMeter riskLevel={riskLevel} />
      </Box>
      <Box mb="16px">
        <ForwardingStatus status={forwardStatus} />
      </Box>
      <Box mb="32px">
        <Typography variant="body1" color="text.secondary">
          <Trans
            i18nKey={
              `assessment.riskLevels.${
                camelCase(riskLevel) as "low"
              }.description` as const
            }
            components={{
              1: <strong />,
            }}
          />
        </Typography>
      </Box>
      <Box mb="16px">
        <Alert severity="info" icon="🤓">
          <Trans
            i18nKey={`assessment.legitimacyInfo.title`}
            components={{
              1: <strong />,
            }}
          />
        </Alert>
      </Box>
      <AssessmentDetails data={assessmentDetails} />
    </Box>
  );
}
