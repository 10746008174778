declare const __FEATURE_FLAGS: typeof import("../../featureFlags.development.json");

type FeatureFlags = typeof import("../../featureFlags.development.json");

type Feature = keyof FeatureFlags;

// This is a simple function for now but it might be extended for more complex cases
const getEnvironmentFeatureFlags = () => {
  return __FEATURE_FLAGS;
};

export function isFeatureEnabled(feature: Feature) {
  const flags = getEnvironmentFeatureFlags();
  if (!flags) {
    return false;
  }
  return flags[feature];
}
