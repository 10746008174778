import { Typography, styled } from "@mui/material";
import { ForwardingStatus, ForwardingStatusProps } from "../ForwardingStatus";
import { ReactNode } from "react";

export type InformativeViewPropsWithDescription = {
  imgSrc: string;
  title: string;
  description: ReactNode;
  forwardingStatus?: ForwardingStatusProps["status"];
};

export type InformativeViewPropsWithForwardingStatus = {
  imgSrc: string;
  title: string;
  description?: ReactNode;
  forwardingStatus: ForwardingStatusProps["status"];
};

export type InformativeViewProps =
  | InformativeViewPropsWithDescription
  | InformativeViewPropsWithForwardingStatus;

const StyledImg = styled("img")`
  width: 100%;
  height: 209px;
  margin-bottom: 30px;
  object-fit: cover;
`;

const ContentWrapper = styled("div")`
  margin-bottom: 16px;
  text-align: center;
`;

const MainWrapper = styled("div")`
  height: 100%;
`;

export function InformativeView({
  imgSrc,
  title,
  description,
  forwardingStatus,
}: InformativeViewProps) {
  return (
    <MainWrapper>
      <ContentWrapper>
        <StyledImg src={imgSrc} alt="Screen illustration" />
        <Typography variant="h1" color="text.primary" gutterBottom>
          {title}
        </Typography>
        {description ? (
          <Typography variant="body1" color="text.secondary" gutterBottom>
            {description}
          </Typography>
        ) : null}
      </ContentWrapper>
      {forwardingStatus ? <ForwardingStatus status={forwardingStatus} /> : null}
    </MainWrapper>
  );
}
