import { useState, useEffect, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { LoadingAnimation } from "./LoadingAnimation.tsx";
import { useLocales } from "../hooks";

const animationFrequency = 10000;

export function LoadingScreen() {
  const { translate } = useLocales();
  const messages = useMemo(
    () => [
      translate("assessment.longLoading.initializeAssessment"),
      translate("assessment.longLoading.analyzeEmail"),
      translate("assessment.longLoading.collectInfo"),
      translate("assessment.longLoading.analyzeExternalInfo"),
      translate("assessment.longLoading.calculateRiskLevel"),
      translate("assessment.longLoading.processingResults"),
    ],
    [translate]
  );

  const [currentMessage, setCurrentMessage] = useState(messages[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessage((prevMessage) => {
        const currentIndex = messages.indexOf(prevMessage);
        const nextIndex = currentIndex + 1;

        if (nextIndex < messages.length) {
          return messages[nextIndex];
        } else {
          clearInterval(interval);
          return prevMessage;
        }
      });
    }, animationFrequency);

    return () => {
      clearInterval(interval);
    };
  }, [messages]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
      }}
    >
      <LoadingAnimation />
      <Typography
        variant="h1"
        sx={{
          mt: 6,
          textAlign: "center",
          fontSize: "18px",
          color: "#111940",
        }}
      >
        {currentMessage}
      </Typography>
    </Box>
  );
}
