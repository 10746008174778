import { useCallback, useMemo, useState } from "react";
import { EmployeeEarning } from "../../api";
import { parseEarningsToRewardEvents } from "./parseEarningsToRewardEvents.ts";
import { sortRewardEvents } from "./sortRewardEvents.ts";

export function useMultiRewardsFlow(earnings: EmployeeEarning[]) {
  const events = useMemo(() => {
    const unsortedEvents = parseEarningsToRewardEvents(earnings);
    return sortRewardEvents(unsortedEvents);
  }, [earnings]);

  const [currentIndex, setCurrentIndex] = useState(0);

  const currentEvent = events[currentIndex] || null;

  const hasNext = currentIndex < events.length - 1;

  const showNextEvent = useCallback(() => {
    if (hasNext) {
      setCurrentIndex((prev) => prev + 1);
    }
  }, [hasNext]);

  return {
    currentEvent,
    hasNext,
    showNextEvent,
    events,
  };
}
