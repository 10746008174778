import { HashMarkPositions, RiskLevel } from "./types";
import hexToRgba from "hex-to-rgba";

export const RISK_LEVELS: RiskLevel[] = [
  "LOW",
  "MODERATE_LOW",
  "MODERATE",
  "MODERATE_HIGH",
  "HIGH",
];

export const RISK_LEVEL_COLOR_MAP: Record<RiskLevel, string> = {
  BYPASS: hexToRgba("#4572F1"),
  LOW: hexToRgba("#4572F1"),
  MODERATE_LOW: hexToRgba("#5B68E0"),
  MODERATE: hexToRgba("#8753B9"),
  MODERATE_HIGH: hexToRgba("#B24094"),
  HIGH: hexToRgba("#DF2A6B"),
};

export const HASH_MARK_COUNT = 13;
export const HASH_MARK_WIDTH = 2;
export const HASH_MARK_HEIGHT = 6;
export const HASH_MARK_GAP = HASH_MARK_HEIGHT * 2;

export const GRADIENT_ID = "risk-gradient";

export const HASH_MARK_POSITIONS: HashMarkPositions = {
  BYPASS: 0,
  LOW: 1,
  MODERATE_LOW: 3,
  MODERATE: 6,
  MODERATE_HIGH: 9,
  HIGH: HASH_MARK_COUNT - 1,
};
