import { getData, postData } from "./crudHandlers";
import { apiPaths } from "./apiPaths";
import {
  AssessmentRequestData,
  AssessmentResponseData,
  GamificationSummary,
  PostApiAssessmentReportByMessageIdFeedbackApiArg,
  PostApiAssessmentReportByMessageIdFeedbackApiResponse,
  UserInfo,
} from "./types";
import { AxiosRequestConfig } from "axios";

export const apiHandlers = {
  info: {
    getUserInfo(config?: AxiosRequestConfig) {
      return getData<UserInfo>(apiPaths.info.getUserInfo, config);
    },
    getBadgeData(config?: AxiosRequestConfig) {
      return getData<GamificationSummary>(apiPaths.info.getBadgeData, config);
    },
  },
  assessment: {
    check(data: AssessmentRequestData, config?: AxiosRequestConfig) {
      return postData<AssessmentResponseData>(apiPaths.assessment.check, {
        ...config,
        data,
      });
    },
    reportFeedback(
      data: PostApiAssessmentReportByMessageIdFeedbackApiArg,
      config?: AxiosRequestConfig,
    ) {
      const { messageId, ...restData } = data;
      return postData<PostApiAssessmentReportByMessageIdFeedbackApiResponse>(
        apiPaths.assessment.reportFeedback.replace("{messageId}", messageId),
        {
          ...config,
          ...restData,
        },
      );
    },
  },
} as const;
