import { Box, SxProps } from "@mui/material";
import logoSrc from "../assets/logo.png";
export default function Logo({ sx }: { sx: SxProps }) {
  return (
    <Box
      sx={{
        width: 40,
        height: 40,
        backgroundImage: `url(${logoSrc})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "center",
        ...sx,
      }}
    ></Box>
  );
}
