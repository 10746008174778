import { RiskMeter } from "../RiskMeter";
import { ForwardingStatus, ForwardingStatusProps } from "../ForwardingStatus.tsx";
import { Alert, Button, Typography } from "@mui/material";
import { Trans } from "react-i18next";
import camelCase from "lodash/camelCase";
import { AssessmentDetails, AssessmentDetailsProps } from "./AssessmentDetails.tsx";
import { RiskLevel } from "../RiskMeter/types.ts";
import { useLocales } from "../../hooks";
import { Link } from "react-router-dom";
import { routes } from "../../settings/routes.ts";

import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { isFeatureEnabled } from "../../featureFlags";

const ButtonWrapper = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  marginBottom: "16px",
});

interface ExternalAssessmentViewProps {
  riskLevel: RiskLevel;
  forwardStatus: ForwardingStatusProps["status"];
  assessmentDetails: AssessmentDetailsProps["data"];
  messageId: string;
}

export function ExternalAssessmentView({
  riskLevel,
  forwardStatus,
  assessmentDetails,
  messageId,
}: ExternalAssessmentViewProps) {
  const { translate } = useLocales();

  return (
    <Box mb="16px">
      <Box mb="45px">
        <RiskMeter riskLevel={riskLevel} />
      </Box>
      <Box mb="16px">
        <ForwardingStatus status={forwardStatus} />
      </Box>
      <Box mb="32px">
        <Typography variant="body1" color="text.secondary">
          <Trans
            i18nKey={`assessment.riskLevels.${camelCase(riskLevel) as "low"}.description` as const}
            components={{
              1: <strong />,
            }}
          />
        </Typography>
      </Box>
      <Box mb="16px">
        <Alert severity="info" icon="🤓">
          <Trans
            i18nKey={`assessment.legitimacyInfo.title`}
            components={{
              1: <strong />,
            }}
          />
        </Alert>
      </Box>

      {isFeatureEnabled("PS-AssessmentExperienceUpgrade") ? (
        <ButtonWrapper>
          <Button
            variant="contained"
            fullWidth
            component={Link}
            to={{
              pathname: routes.assessment.provideFeedback.index,
              state: { messageId },
            }}
          >
            {translate("assessment.provideFeedback.action.button")}
          </Button>
        </ButtonWrapper>
      ) : null}

      <AssessmentDetails data={assessmentDetails} />
    </Box>
  );
}
