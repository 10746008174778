import {
  enUS as coreEnUS,
  jaJP as coreJaJP,
  Localization,
} from "@mui/material/locale";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { AppLocale } from "../types";
import { getItem } from "../utils/localStorage.ts";

export interface Language {
  label: string;
  value: AppLocale;
  systemValue: Localization;
}

const allLanguages: Language[] = [
  {
    label: "English",
    value: "en-US",
    systemValue: coreEnUS,
  },
  {
    label: "Japanese",
    value: "ja",
    systemValue: coreJaJP,
  },
];

export function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = getItem("i18nextLng");
  const currentLanguage =
    allLanguages.find((lang) => lang.value === langStorage) ??
    allLanguages.at(0);

  const handleChangeLanguage = useCallback(
    (value: Language["value"]) => {
      i18n.changeLanguage(value);
    },
    [i18n]
  );

  return useMemo(() => {
    return {
      handleChangeLanguage,
      translate,
      currentLanguage,
      allLanguages,
    };
  }, [currentLanguage, handleChangeLanguage, translate]);
}
